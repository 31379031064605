import { HashRouter, Route, Routes } from "react-router-dom";
// import ScrollToTop from "./Components/ScrollToTop"; // Импортируем новый компонент
import App from "./App";
import { MainPage } from "./Components/MainPage/MainPage";
import { ShellPage } from "./Components/ShellPage/ShellPage";
import { MetaPage } from "./Components/MetaPage/MetaPage";
import { LuminaPage } from "./Components/LuminaPage/LuminaPage";
import { PPLuminaPage } from "./Components/LuminaPage/PPLuminaPage";
import { GreitPage } from "./Components/GreitPage/GreitPage";
import { SafePage } from "./Components/SafePage/SafePage";
import { CentrinoPage } from "./Components/CentrinoPage/CentrinoPage";
import { BoostchainPage } from "./Components/BoostchainPage/BoostchainPage";
import { BoostchainTgPage } from "./Components/BoostchainPage/BootschainTgPage";
import { CryptoPage } from "./Components/CryptoPage/CryptoPage";
import { MatchPage } from "./Components/MatchPage/MatchPage";
import { MerchainPage } from "./Components/MerchainPage/MerchainPage";
import { DigitalPage } from "./Components/DigitalPage/DigitalPage";
import { BlinkswapPage } from "./Components/BlinkswapPage/BlinkswapPage";
import { DesignPage } from "./Components/DesignPage/DesignPage";
import { MobileAppPage } from "./Components/MobileAppPage/MobileAppPage";
import { WebDevPage } from "./Components/WebDevPage/WebDevPage";
import { MarketPlacePage } from "./Components/MarketPlacePage/MarketPlacePage";
import { CurrencyPage } from "./Components/CurrencyPage/CurrencyPage";
import { SocialPage } from "./Components/SocialPage/SocialPage";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";

export const Root = () => {
  return (
    <HashRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<MainPage />} />
          <Route path="meta-verified" element={<MetaPage />} />
          <Route path="shell" element={<ShellPage />} />
          <Route path="lumina" element={<LuminaPage />} />
          <Route path="lumina-pp" element={<PPLuminaPage />} />
          <Route path="greig-and-yard" element={<GreitPage />} />
          <Route path="safe-wallet" element={<SafePage />} />
          <Route path="centrino" element={<CentrinoPage />} />
          <Route path="boostchain" element={<BoostchainPage />} />
          <Route path="boostchain-bot" element={<BoostchainTgPage />} />
          <Route path="crypto-store" element={<CryptoPage />} />
          <Route path="inmatch" element={<MatchPage />} />
          <Route path="merchain" element={<MerchainPage />} />
          <Route path="digitalassetdealer" element={<DigitalPage />} />
          <Route path="blinkswap" element={<BlinkswapPage />} />
          <Route path="design" element={<DesignPage />} />
          <Route path="mobile-app-development" element={<MobileAppPage />} />
          <Route path="web-development" element={<WebDevPage />} />
          <Route path="marketplace-development" element={<MarketPlacePage />} />
          <Route path="cryptocurrency-solutions" element={<CurrencyPage />} />
          <Route path="social-media-advertising" element={<SocialPage />} />
        </Route>
      </Routes>
    </HashRouter>
  );
};
