import "./header.scss";
import "../../styles/page.scss";
import { NavLink } from "react-router-dom";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { Link } from "react-scroll";
// import { Link } from "react-scroll";
// import { HashLink } from "react-router-hash-link";
// import { Link } from "react-scroll";
// import { HashLink } from "react-router-hash-link";

export const Header = () => {
  return (
    <header className="header">
      <MobileMenu />
      <div className="header-container">
        <NavLink to="/" className="page-logo-container">
          <img
            src="images/S-letter.svg"
            className="page-logo page-logo-S"
            alt="logo"
          />
          <img
            src="images/O-letter.svg"
            className="page-logo page-logo-O"
            alt="logo"
          />
          <img
            src="images/B-letter.svg"
            className="page-logo page-logo-B"
            alt="logo"
          />
          <img
            src="images/E-letter.svg"
            className="page-logo page-logo-E"
            alt="logo"
          />
          <img
            src="images/X-letter.svg"
            className="page-logo page-logo-X"
            alt="logo"
          />
        </NavLink>
        <ul className="header__list">
          <li className="header__item">
            <Link
              to="about"
              smooth={true}
              duration={500}
              className="page__link"
            >
              About us
            </Link>
          </li>
          <li className="header__item">
            <Link
              to="partners"
              smooth={true}
              duration={500}
              className="page__link"
            >
              Our partners
            </Link>
          </li>
          <li className="header__item">
            <Link
              to="services"
              smooth={true}
              duration={500}
              className="page__link"
            >
              Services
            </Link>
          </li>
          <li className="header__item">
            <Link
              to="our-works"
              smooth={true}
              duration={500}
              className="page__link"
            >
              Our works
            </Link>
          </li>
          <li className="header__item">
            <Link
              to="contacts"
              smooth={true}
              duration={500}
              className="page__link"
            >
              Contacts
            </Link>
          </li>
        </ul>
      </div>
      <div className="header-container-decoration">
        <div className="header__title-decoration-container">
          <h2 className="header__title-decoration">
            An integrated digital and fintech agency offering a full range of
            services
          </h2>
          <div className="header-circle"></div>
          <img
            src="images/header-shadow.png"
            className="header-shadow header-shadow-first"
            alt="shadow"
          />
          <img
            src="images/header-shadow-1.png"
            className="header-shadow header-shadow-second"
            alt="shadow"
          />
          <img
            src="images/header-shadow-2.png"
            className="header-shadow header-shadow-third"
            alt="shadow"
          />
          <img
            src="images/header-shadow-3.png"
            className="header-shadow header-shadow-fourth"
            alt="shadow"
          />
          <img
            src="images/header-shadow-4.png"
            className="header-shadow header-shadow-fourth"
            alt="shadow"
          />
        </div>
      </div>
      <div className="header__main-container">
        <div>
          <h1 className="header__title header__title-first">
            Innovating Excellence
          </h1>
          <h1 className="header__title header__title-second">
            in Digital Solutions
          </h1>
        </div>
        <div className="header__button-container">
          <Link
            to="form"
            smooth={true}
            duration={500}
            className="page__button header__button"
          >
            Let`s talk
          </Link>
          <p className="page__subtitle header__subtitle">
            Delivering amazing experiences is nothing but our goal, we are
            crafting, building valuable technologies for brands, digital
            products and any human being.
          </p>
        </div>
      </div>
      <img
        id="about"
        src="images/decoration-bottom.png"
        className="header-bottom"
        alt="decoration"
      />
    </header>
  );
};
