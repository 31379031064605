import { NavLink } from "react-router-dom";
import '../../styles/page.scss';

type Props = {
  link: string
}

export const PageFooterButton: React.FC<Props> = ({ link }) => {
  return (
    <div className="page__footer__button-container">
      <NavLink to={`${link}`} className="page__footer__button">
        Next Project
      </NavLink>
    </div>
  );
};
