import "./meta.scss";
import "../../styles/page.scss";
import { NavLink } from "react-router-dom";
import { SecondPartOfFooter } from "../SecondPartOfFooter/SecondPartOfFooter";
import { PageSection } from "../PageSection/PageSection";
import { PageFooterButton } from "../PageFooterButton/PageFooterButton";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { HashLink } from "react-router-hash-link";
import { PageListLinks } from "../PageListLinks/PageListLinks";

export const MetaPage = () => {
  const colors = [
    {
      color: "#EDFAFB",
      codeName: "LightBlue",
      name: "Light Blue",
      subtitleNames: ["#EDFAFB", "R251, G250, B251"],
      order: "first",
      textColor: "black",
    },
    {
      color: "#FFDBEE",
      codeName: "LightPink",
      name: "Light Pink",
      subtitleNames: ["#FFDBEE", "R255, G211, B238"],
      order: "second",
      textColor: "black",
    },
    {
      color: "#E38AEF",
      codeName: "Pink",
      name: "Pink",
      subtitleNames: ["#E38AEF", "R227, G162, B252"],
      order: "third",
      textColor: "black",
    },
    {
      color: "#858EFA",
      codeName: "ShadowBlue",
      name: "Shadow Blue",
      subtitleNames: ["#858EFA", "R133, G142, B250"],
      order: "fourth",
      textColor: "black",
    },
    {
      color: "#FFFFFF",
      codeName: "White",
      name: "White",
      subtitleNames: ["#FFFFFF", "R255, G255, B255"],
      order: "fifth",
      textColor: "black",
    },
    {
      color: "#AAB2BD",
      codeName: "CadetGrey",
      name: "Cadet Grey",
      subtitleNames: ["#AAB2BD", "R168, G178, B189"],
      order: "sixth",
      textColor: "black",
    },
    {
      color: "#0087ED",
      codeName: "Blue",
      name: "Blue",
      subtitleNames: ["#0087ED", "R0, G135, B224"],
      order: "seventh",
      textColor: "black",
    },
    {
      color: "#3A3E61",
      codeName: "Violet",
      name: "Violet",
      subtitleNames: ["#3A3E61", "R58, G62, B81"],
      order: "eighth",
      textColor: "white",
    },
    {
      color: "#0B2626",
      codeName: "Green",
      name: "Green",
      subtitleNames: ["#0B2626", "R11, G43, B38"],
      order: "ninth",
      textColor: "white",
    },
    {
      color: "#000000",
      codeName: "Black",
      name: "Black",
      subtitleNames: ["#000000", "R0, G0, B0"],
      order: "tenth",
      textColor: "white",
    },
  ];

  return (
    <>
      <header className="meta">
        <div className="meta-container">
          <MobileMenu />
          <div className="meta__header">
            <NavLink to="/" className="page-logo-container">
              <img
                src="images/S-letter.svg"
                className="page-logo page-logo-S"
                alt="logo"
              />
              <img
                src="images/O-letter.svg"
                className="page-logo page-logo-O"
                alt="logo"
              />
              <img
                src="images/B-letter.svg"
                className="page-logo page-logo-B"
                alt="logo"
              />
              <img
                src="images/E-letter.svg"
                className="page-logo page-logo-E"
                alt="logo"
              />
              <img
                src="images/X-letter.svg"
                className="page-logo page-logo-X"
                alt="logo"
              />
            </NavLink>
            <PageListLinks />
          </div>
          <div className="meta__main-container">
            <div className="meta__title-container">
              <h1 className="meta__title">meta-Verified</h1>
              <h2 className="meta__subtitle">Some text about app</h2>
            </div>
            <img
              src="images/ellipse-red.png"
              className="meta-img-red"
              alt="decoration"
            />
            <img
              src="images/ellipse-blue.png"
              className="meta-img-blue"
              alt="decoration"
            />
            <img
              src="images/phone-decoration.png"
              className="meta-img"
              alt="phone"
            />
          </div>
        </div>
      </header>
      <PageSection
        boldTitle={"Meta-Verifie. "}
        title={<>BRAND and APP</>}
        paragraph={
          <>
            Instagram Account Verification Project<br></br>
            <br></br>
            Our team successfully implemented a unique project aimed at helping
            users obtain the coveted blue checkmark on Instagram. We created a
            system where collaboration with popular bloggers allowed accounts to
            be verified through their official support and audience.<br></br>
            <br></br>
            How It Worked<br></br>
            Bloggers provided their accounts for the verification process, and
            our team ensured transparency and security at every stage. Users
            could choose a suitable package, after which we initiated the
            process by working directly with Instagram.<br></br>
            <br></br>
            Why It Matters<br></br>
            The blue checkmark helps profiles stand out, increases trust, and
            enhances visibility. This project combined our team’s resources with
            the influence of bloggers, making verification accessible to a
            larger number of users.<br></br>
            <br></br>
            This project was a valuable experience for us, allowing us to gain
            deeper insights into the verification process and develop effective
            solutions for a wider audience.
          </>
        }
        year={"2021"}
        industry={
          <>
            UI/UX Design<br></br>Frontend and Backend<br></br>User Flow Mapping
            <br></br>Content Moderation and Management<br></br>Compliance and{" "}
            <br></br> Security
          </>
        }
        projectDescription={
          <>
            {" "}
            Creating a verification system through collaboration with
            influencers and bloggers<br></br>Ensuring secure and transparent
            verification processes for Instagram accounts<br></br>Enhancing user
            trust and visibility by obtaining the blue checkmark
          </>
        }
      />
      <section className="colours">
        <div className="colours__main-container">
          {colors.map((color) => (
            <div
              className={`colours-container colours-container-${color.order}`}
              style={{ backgroundColor: color.color }}
            >
              <h2 className="colours__title" style={{ color: color.textColor }}>
                {color.name}
              </h2>
              <div className="colours__subtitle-container">
                <h3
                  className="colours__subtitle"
                  style={{ color: color.textColor }}
                >
                  {color.color}
                </h3>
                {color.subtitleNames.slice(1).map((subtitleName) => (
                  <h3
                    className="colours__subtitle"
                    style={{ color: color.textColor }}
                  >
                    {subtitleName}
                  </h3>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
      <img
        src="images/qwerty-img.png"
        className="qwerty-img"
        alt="qwerty-img"
      />
      <footer className="meta__footer">
        <div className="meta__footer-container">
          <img
            src="images/phone-replenish.png"
            className="meta__footer-img meta__footer-img-flank meta__footer-img-flank-first"
            alt="phone"
          />
          <img
            src="images/main-decoration.png"
            className="meta__footer-img meta__footer-img-center-2"
            alt="phone"
          />
          <img
            src="images/phone-transfer.png"
            className="meta__footer-img meta__footer-img-flank meta__footer-img-flank-second"
            alt="phone"
          />
        </div>
        <div>
          <img
            src="images/phone-new-akk.png"
            className="meta__footer-img meta__footer-img-flank-2"
            alt="phone"
          />
          <img
            src="images/phone-verify.png"
            className="meta__footer-img meta__footer-img-center"
            alt="phone"
          />
          <img
            src="images/phone-partners.png"
            className="meta__footer-img meta__footer-img-center"
            alt="phone"
          />
          <img
            src="images/phone-verification.png"
            className="meta__footer-img meta__footer-img-flank-2"
            alt="phone"
          />
        </div>
        <PageFooterButton link="/shell" />
        {/* <h2 className="meta__footer__button">Next Project</h2> */}
      </footer>
      <SecondPartOfFooter />
    </>
  );
};
