import { NavLink } from "react-router-dom";
import Carousel from "../Carousel/Carousel";
import "../Footer/footer.scss";
import { useState } from "react";
import { cryptoSteps as steps } from "../../utils/base";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { HashLink } from "react-router-hash-link";
import { Form } from "../Form/Form";
import { PageListLinks } from "../PageListLinks/PageListLinks";
import { Link } from "react-scroll";

export const CurrencyPage = () => {
  const elements = [
    { id: 1, name: "CRYPTO WALLETS" },
    { id: 2, name: "CRYPTO EXCHANGES" },
    { id: 3, name: "SMART CONTRACTS" },
    { id: 4, name: "SECURITY AND COMPLIANCE" },
    { id: 5, name: "NFT INTEGRATION" },
    { id: 6, name: "COMPLIANCE AND KYC SOLUTIONS" },
    { id: 7, name: "STAKING PLATFORMS" },
  ];

  const initialSections = steps.map((step) => step.details[0].section);
  const initialDescriptions = steps.map((step) => step.details[0].description);

  const [selectedSections, setSelectedSections] = useState(initialSections);
  const [descriptions, setDescriptions] = useState(initialDescriptions);

  // @ts-ignore
  const updateSectionAndDescription = (index, newSection) => {
    const newDescriptions = [...descriptions];
    const newSections = [...selectedSections];

    const newDescription = steps
      .flatMap((step) => step.details)
      .find((detail) => detail.section === newSection)?.description;
    // @ts-ignore
    newDescriptions[index] = newDescription;
    newSections[index] = newSection;

    setDescriptions(newDescriptions);
    setSelectedSections(newSections);
  };

  return (
    <>
      <header className="page__header-design-container">
        <MobileMenu />
        <div className="page-design__header">
          <NavLink to="/" className="page-logo-container">
            <img
              src="images/S-letter.svg"
              className="page-logo page-logo-S"
              alt="logo"
            />
            <img
              src="images/O-letter.svg"
              className="page-logo page-logo-O"
              alt="logo"
            />
            <img
              src="images/B-letter.svg"
              className="page-logo page-logo-B"
              alt="logo"
            />
            <img
              src="images/E-letter.svg"
              className="page-logo page-logo-E"
              alt="logo"
            />
            <img
              src="images/X-letter.svg"
              className="page-logo page-logo-X"
              alt="logo"
            />
          </NavLink>
          <PageListLinks />
        </div>
        <Carousel />
        <div className="page-design__header__main-container">
          <div className="page-design__header__title__main-container">
            <div className="page-design__header__title-container">
              <h1 className="page-design__header__title">we</h1>
              <h1 className="page-design__header__title-second">create</h1>
            </div>
            <p className="page-design__header__paragraph">
              We create digital products that improve people’s lives, from
              concept development to interface design and full-stack
              implementation. Our team combines the power of advanced technology
              with high-quality design to provide users with intuitive,
              user-friendly, and effective solutions.
            </p>
          </div>
          <Link
            to="form"
            smooth={true}
            duration={500}
            className="page__button page__button-design"
          >
            Let`s talk
          </Link>
        </div>
        {/* <h2 className="page-design-title-large">Mobile App De</h2> */}
        <img
          src="images/market-place-text.png"
          className="page-img-design"
          style={{ bottom: "-100px" }}
          alt="design"
        />
      </header>
      <main className="page-design__main page__section">
        <div className="page-design__item__main-container">
          <div className="page-design__item-container">
            {elements.map((element) => (
              <h3 className="page-design__item">{element.name}</h3>
            ))}
          </div>
          <p className="page-design__paragraph">
            We develop comprehensive cryptocurrency solutions, providing a
            convenient, secure, and flexible user experience. Our team considers
            the specifics of your business and the needs of your target audience
            to create a platform that addresses practical challenges and meets
            high security standards. We utilize modern technologies and adopt
            innovative approaches to ensure stable performance and data
            protection at every stage.<br></br>
            <br></br>
            We support you through every step of development, from designing and
            integrating smart contracts to launch and ongoing compliance with
            security standards and regulatory requirements.
          </p>
        </div>
        <div className="page__section">
          <h2 className="page-design__title">Work Process</h2>
          <div className="page__card__main-container">
            {steps.map((step, index) => (
              <div className="page__card-container">
                <h3 className="page__card__number">{`0${step.step}`}</h3>
                <div className="page__card" key={step.step}>
                  <h2 className="page__card__title">{step.title}</h2>
                  <div className="page__card__subtitle-container">
                    {step.details.map((detail) => (
                      <button
                        key={detail.section}
                        onClick={() =>
                          updateSectionAndDescription(index, detail.section)
                        }
                        className="page__card__subtitle"
                        style={{
                          margin: "5px",
                          padding: "10px 15px",
                          cursor: "pointer",
                          borderTop:
                            detail.section === selectedSections[index]
                              ? "3px solid #000000"
                              : "none",
                          color:
                            detail.section === selectedSections[index]
                              ? "#000000"
                              : "#CBCBCB",
                          borderRadius: "0px",
                        }}
                      >
                        {detail.section}
                      </button>
                    ))}
                  </div>

                  <div>
                    <p className="page__card__paragraph">
                      {descriptions[index]}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
      <footer className="footer footer-design">
        <article className="form__article form__article-design">
          <Form />
        </article>
        <article className="page__section">
          <div className="footer__list-container">
            <p className="footer__subtitle-experience">
              Experience the future with a crazy experienced team.
            </p>
            <ul className="footer__list">
              <li className="footer__item">
                <HashLink smooth to="/#about" className="footer__link">
                  About us
                </HashLink>
              </li>
              <li className="footer__item">
                <HashLink smooth to="/#partners" className="footer__link">
                  Projects
                </HashLink>
              </li>
              <li className="footer__item">
                <HashLink smooth to="/#services" className="footer__link">
                  Services
                </HashLink>
              </li>
              <li className="footer__item">
                <HashLink smooth to="/#our-works" className="footer__link">
                  Our works
                </HashLink>
              </li>
              <li className="footer__item">
                <HashLink smooth to="/#contacts" className="footer__link">
                  Contacts
                </HashLink>
              </li>
            </ul>
            <div>
              <h3 className="footer__title-clients">Selected Clients</h3>
              <p className="footer__subtitle-secondary">
                Meta Verified, BitCore Network,BitCore Pay, Merchant, BitCore
                App
              </p>
            </div>
          </div>
          <div className="footer-logo-container">
            <a
              href="https://www.instagram.com/sobex.agency?igsh=YzhlaXJzbW5wMWRh&utm_source=qr"
              target="_blank"
              rel="noreferrer"
            >
              <img src="images/instagram.svg" alt="instagram" />
            </a>
            <a href="/">
              <img src="images/telegram.svg" alt="telegram" />
            </a>
            <a
              href="https://www.linkedin.com/feed/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="images/linkedin.svg" alt="linkedin" />
            </a>
          </div>
        </article>
      </footer>
      <footer className="footer footer-second">
        <article className="page__section footer-container-second">
          <h2 className="footer__subtitle">
            ©2023 sobeX, Worldwide. All rights reserved.
          </h2>
          <div className="footer__subtitle-container">
            <h2 className="footer__subtitle">Privacy Policy</h2>
            <h2 className="footer__subtitle">·</h2>
            <h2 className="footer__subtitle">Terms of Conditions</h2>
          </div>
        </article>
      </footer>
    </>
  );
};
