import "../../styles/page.scss";

type Props = {
  boldTitle: string;
  title: string | React.ReactNode;
  secondTitle?: string | React.ReactNode;
  paragraph: React.ReactNode;
  year: string;
  industry: React.ReactNode;
  projectDescription: React.ReactNode;
};

export const PageSection: React.FC<Props> = ({
  boldTitle,
  title,
  secondTitle,
  paragraph,
  year,
  industry,
  projectDescription,
}) => {
  return (
    <section className="page__section page__section-meta">
      <h2 className="page__title">
        <span className="page__title-bold">{boldTitle}</span>
        {title}
      </h2>
      {secondTitle && <h2 className="page__title" style={{ margin: '0' }}>{secondTitle}</h2>}
      <div className="page-container">
        <div className="page__paragraph-container">
          <h2 className="page__title-small">project description</h2>
          <p className="page__paragraph">{paragraph}</p>
        </div>
        <div className="page-info__title__main-container">
          <div className="page-info__title-container">
            <h3 className="page-info__title">year:</h3>
            <h3 className="page-info__subtitle">{year}</h3>
          </div>
          <div className="page-info__title-container">
            <h3 className="page-info__title">industry:</h3>
            <h3 className="page-info__subtitle">{industry}</h3>
          </div>
          <div className="page-info__title-container">
            <h3 className="page-info__title">Project direction:</h3>
            <h3 className="page-info__subtitle">{projectDescription}</h3>
          </div>
        </div>
      </div>
    </section>
  );
};
