import { NavLink } from "react-router-dom";
import "../../styles/page.scss";
import "./ShellPage.scss";
import { SecondPartOfFooter } from "../SecondPartOfFooter/SecondPartOfFooter";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { PageFooterButton } from "../PageFooterButton/PageFooterButton";
import { PageListLinks } from "../PageListLinks/PageListLinks";

export const ShellPage = () => {
  const colors = [
    {
      color: "#A7A7A7",
      codeName: "LightBlue",
      name: "Light Blue",
      subtitleNames: ["#A7A7A7", "R167, G167, B167"],
      order: "first",
      textColor: "black",
    },
    {
      color: "#F8EC07",
      codeName: "LightPink",
      name: "Light Pink",
      subtitleNames: ["#F8EC07", "R251, G206, B7"],
      order: "second",
      textColor: "black",
    },
    {
      color: "#EEC200",
      codeName: "Pink",
      name: "Pink",
      subtitleNames: ["#EEC200", "R238, G194, B0"],
      order: "third",
      textColor: "black",
    },
    {
      color: "#DD1121",
      codeName: "ShadowBlue",
      name: "Shadow Blue",
      subtitleNames: ["#DD1121", "R221, G29, B33"],
      order: "fourth",
      textColor: "black",
    },
    {
      color: "#FFFFFF",
      codeName: "White",
      name: "White",
      subtitleNames: ["#FFFFFF", "R255, G255, B255"],
      order: "fifth",
      textColor: "black",
    },
    {
      color: "#222222",
      codeName: "CadetGrey",
      name: "Cadet Grey",
      subtitleNames: ["#222222", "R34, G34, B34"],
      order: "sixth",
      textColor: "white",
    },
  ];

  return (
    <>
      <header className="page__header__main-container">
        <div className="page__header-container">
          <MobileMenu />
          <div className="page__header">
            <NavLink to="/" className="page-logo-container">
              <img
                src="images/S-letter.svg"
                className="page-logo page-logo-S"
                alt="logo"
              />
              <img
                src="images/O-letter.svg"
                className="page-logo page-logo-O"
                alt="logo"
              />
              <img
                src="images/B-letter.svg"
                className="page-logo page-logo-B"
                alt="logo"
              />
              <img
                src="images/E-letter.svg"
                className="page-logo page-logo-E"
                alt="logo"
              />
              <img
                src="images/X-letter.svg"
                className="page-logo page-logo-X"
                alt="logo"
              />
            </NavLink>
            <PageListLinks />
          </div>
          <div className="shell__main-container">
            <div className="shell__title-container">
              <h1 className="shell__title">Shell</h1>
              <h2 className="shell__subtitle">Admin panel design</h2>
            </div>
            <img
              src="images/notebook-img.png"
              className="shell-img"
              alt="notebook-img"
            />
            <img
              src="images/shadow-img.png"
              className="shell-img-shadow"
              alt="shadow-img"
            />
          </div>
        </div>
      </header>
      <section className="page__section">
        <h2 className="page__title">
          <span className="page__title-bold">Shell. </span> Brand and Website
          Design
        </h2>
        <div className="page-container">
          <div className="page__paragraph-container">
            <h2 className="page__title-small">project description</h2>
            <p className="page__paragraph">
              Website Development Project for Shell<br></br>
              <br></br>
              Our team successfully completed a project to develop a new website
              for Shell. We began with an in-depth market and competitor
              analysis to identify key elements that would help Shell stand out
              and meet the needs of its audience.<br></br>
              <br></br>
              What We Did:<br></br>
              <br></br>
              Competitor Analysis and Current Site Audit – conducted a detailed
              analysis, identified strengths and weaknesses, and determined best
              practices in the industry.<br></br>
              <br></br>
              UI/UX Design and Prototyping – created a modern, user-friendly
              interface focused on user needs, with easy navigation and quick
              access to essential information.<br></br>
              <br></br>
              Development and Integration – built the website using advanced
              technologies, ensuring compatibility across all devices and
              integration with the company's internal systems.<br></br>
              <br></br>
              Optimization and Testing – tested the website for performance and
              security, optimized loading speed, and adapted it for SEO.
              <br></br>
              <br></br>
              Result:<br></br>
              The updated website has become a valuable tool for Shell,
              providing a seamless user experience for clients and enhancing
              brand visibility
            </p>
          </div>
          <div className="page-info__title__main-container">
            <div className="page-info__title-container">
              <h3 className="page-info__title">year:</h3>
              <h3 className="page-info__subtitle">2021</h3>
            </div>
            <div className="page-info__title-container">
              <h3 className="page-info__title">industry:</h3>
              <h3 className="page-info__subtitle">
                UI/UX Design<br></br>Design Systems<br></br>Frontend and Backend
              </h3>
            </div>
            <div className="page-info__title-container">
              <h3 className="page-info__title">Project direction:</h3>
              <h3 className="page-info__subtitle">
                Web design<br></br>Web Development<br></br>Social Media&
                Advertising
              </h3>
            </div>
          </div>
        </div>
      </section>
      <section className="page-colours">
        <div className="page-colours__main-container">
          {colors.map((color) => (
            <div
              className={`page-colours-container page-colours-container-${color.order}`}
              style={{ backgroundColor: color.color }}
            >
              <h2
                className="page-colours__title"
                style={{ color: color.textColor }}
              >
                {color.name}
              </h2>
              <div className="page-colours__subtitle-container">
                <h3
                  className="page-colours__subtitle"
                  style={{ color: color.textColor }}
                >
                  {color.color}
                </h3>
                {color.subtitleNames.slice(1).map((subtitleName) => (
                  <h3
                    className="page-colours__subtitle"
                    style={{ color: color.textColor }}
                  >
                    {subtitleName}
                  </h3>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className="page__section">
        <img
          src="images/three-phones-img.png"
          className="phone-img page-img"
          alt="three-phones"
        />
      </section>
      <img
        src="images/qwerty-img.png"
        className="qwerty-img page-img"
        alt="qwerty-img"
      />
      <footer className="shell__footer">
        <div className="shell-img-container">
          <img
            src="images/shell__footer-img.png"
            className="shell__footer-img shell__footer-img-first page-img"
            alt="notebook-img"
          />
          {/* <img
            src="images/mockup-img.png"
            className="shell__footer-img shell__footer-img-mockup page-img"
            alt="mockup"
          />
          <img
            src="images/notebook-img-3.png"
            className="shell__footer-img shell__footer-img-second page-img"
            alt="notebook-img"
          />
          <img
            src="images/notebook-img-4.png"
            className="shell__footer-img shell__footer-img-third page-img"
            alt="notebook-img"
          /> */}
        </div>
      </footer>
      <PageFooterButton link="/lumina" />
      <SecondPartOfFooter />
    </>
  );
};
