import "./lumina.scss";
import "../../styles/page.scss";
import { PageSection } from "../PageSection/PageSection";
import { ColoursSection } from "../ColoursSection/ColoursSection";
import { NavLink } from "react-router-dom";
import { SecondPartOfFooter } from "../SecondPartOfFooter/SecondPartOfFooter";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { PageListLinks } from "../PageListLinks/PageListLinks";
import { PageFooterButton } from "../PageFooterButton/PageFooterButton";

export const LuminaPage = () => {
  const colors = [
    {
      color: "#F5FFD0",
      codeName: "LightGreen",
      name: "Light Green",
      subtitleNames: ["#F5FFD0", "R245, G255, B221"],
      order: "first",
      textColor: "black",
    },
    {
      color: "#2B714B",
      codeName: "Green",
      name: "Green",
      subtitleNames: ["#2B714B", "R43, G113, B75"],
      order: "second",
      textColor: "white",
    },
    {
      color: "#4A4A4A",
      codeName: "Grey",
      name: "Grey",
      subtitleNames: ["#4A4A4A", "R74, G74, B74"],
      order: "third",
      textColor: "white",
    },
    {
      color: "#D9D9D9",
      codeName: "LightGrey",
      name: "Light Grey",
      subtitleNames: ["#D9D9D9", "R217, G217, B217"],
      order: "fourth",
      textColor: "black",
    },
    {
      color: "#FFFFFF",
      codeName: "White",
      name: "White",
      subtitleNames: ["#FFFFFF", "R255, G255, B255"],
      order: "fifth",
      textColor: "black",
    },
    {
      color: "#F0F0F0",
      codeName: "CadetGrey",
      name: "Cadet Grey",
      subtitleNames: ["#F0F0F0", "R240, G240, B240"],
      order: "sixth",
      textColor: "black",
    },
  ];

  return (
    <>
      <header className="page__header__main-container">
        <div className="page__header-container">
          <MobileMenu />
          <div className="page__header">
            <NavLink to="/" className="page-logo-container">
              <img
                src="images/S-letter.svg"
                className="page-logo page-logo-S"
                alt="logo"
              />
              <img
                src="images/O-letter.svg"
                className="page-logo page-logo-O"
                alt="logo"
              />
              <img
                src="images/B-letter.svg"
                className="page-logo page-logo-B"
                alt="logo"
              />
              <img
                src="images/E-letter.svg"
                className="page-logo page-logo-E"
                alt="logo"
              />
              <img
                src="images/X-letter.svg"
                className="page-logo page-logo-X"
                alt="logo"
              />
            </NavLink>
            <PageListLinks />
          </div>
          <div className="lumina__main-container">
            <div className="lumina__title-container">
              <h1 className="lumina__title">Lumina</h1>
              <h2 className="shell__subtitle">Investment fund</h2>
            </div>
            <img
              src="images/notebook-img-lumina-header.png"
              className="lumina-img"
              alt="notebook-img"
            />
            <img
              src="images/shadow-img-lumina.png"
              className="lumina-img-shadow"
              alt="shadow-img"
            />
          </div>
        </div>
      </header>
      <PageSection
        industry={
          <>
            Web design
            <br />
            Web Development
            <br />
            Social Media
          </>
        }
        boldTitle={"Lumina."}
        title={"brand and Website Design"}
        paragraph={
          <>
            Landing Page Development Project for an Investment Fund <br></br>
            <br></br>
            Our team successfully developed a landing page for an investment
            fund from scratch, focusing on every detail, from color selection to
            UX/UI design. The goal was to create an attractive and user-friendly
            website that builds trust with potential investors and provides easy
            access to key information.<br></br>
            <br></br>
            What We Did:<br></br>
            <br></br>
            Target Audience Research and Color Palette Selection – analyzed the
            target audience’s preferences and selected calm, professional colors
            associated with reliability and stability.<br></br>
            <br></br>
            UI/UX Design and Layout Creation – designed a clear and visually
            appealing interface, with an intuitive structure and navigation,
            allowing users to easily explore the site.<br></br>
            <br></br>
            Development and Mobile Adaptation – the site was fully optimized for
            mobile devices, ensuring accessibility and convenience for users on
            all platforms.<br></br>
            <br></br>
            Integration of Analytics Tools and SEO Optimization – implemented
            tools to track user behavior and performed SEO optimization to
            improve the site’s visibility in search engines.<br></br>
            <br></br>
            Result:<br></br>
            The landing page became an effective tool for the investment fund,
            attracting potential clients and leaving a positive impression of
            the brand.
          </>
        }
        year={"2024"}
        projectDescription={
          <>
            Brand identity<br></br>Web design<br></br>Social Media& Advertising
          </>
        }
      />
      <ColoursSection colours={colors} />
      <section className="lumina"></section>
      <section className="page__section">
        <img
          src="images/qwerty-img.png"
          className="lumina-img-qwerty page-img"
          alt="qwerty"
        />
        <img
          src="images/qwerty-img.png"
          className="lumina-img-qwerty page-img"
          alt="qwerty"
        />
        <img
          src="images/qwerty-img-2.png"
          className="lumina-img-qwerty page-img"
          alt="qwerty"
        />
      </section>
      <img
        src="images/lumina-phones-img.png"
        className="lumina-img-phones page-img"
        alt="phones"
      />
      <footer className="page__footer">
        <div className="lumina-img-container">
          <img
            src="images/notebook-img-lumina-footer-1.png"
            className="lumina-img-notebook lumina-img-notebook-first"
            alt="notebook"
          />
          {/* <img
          src="images/mockup-img.png"
          className="lumina-img-mockup"
          alt="mockup"
        /> */}
          <img
            src="images/notebook-img-lumina-footer-2.png"
            className="lumina-img-notebook lumina-img-notebook-second"
            alt="notebook"
          />
        </div>

        <PageFooterButton link="/lumina-pp" />
      </footer>
      <SecondPartOfFooter />
    </>
  );
};
