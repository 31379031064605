import { useInView } from "react-intersection-observer";
import { ColorItem } from "../../types/card";

type Props = {
  colours: ColorItem[];
};

export const ColoursSection: React.FC<Props> = ({ colours }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <>
      <section className="page-colours__title-img-container">
        <h2
          ref={ref}
          className={`page-colours__title-large ${inView ? "animate" : ""}`}
        >
          Colours
        </h2>
        {/* <img
          ref={ref}
          src="images/colours-text.png"
          className={`page-colours__title-large ${inView ? "animate" : ""}`}
          alt="text-img"
        /> */}
      </section>
      <section className="page-colours">
        <div className="page-colours__main-container">
          {colours.map((color) => (
            <div
              className={`page-colours-container page-colours-container-${color.order}`}
              style={{ backgroundColor: color.color }}
            >
              <h2
                className="page-colours__title"
                style={{ color: color.textColor }}
              >
                {color.name}
              </h2>
              <div className="page-colours__subtitle-container">
                <h3
                  className="page-colours__subtitle"
                  style={{ color: color.textColor }}
                >
                  {color.color}
                </h3>
                {color.subtitleNames.slice(1).map((subtitleName) => (
                  <h3
                    className="page-colours__subtitle"
                    style={{ color: color.textColor }}
                  >
                    {subtitleName}
                  </h3>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};
