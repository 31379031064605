import { cardData } from "../../utils/base";
import { MetaCard } from "../MetaCard/MetaCard";
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Base Swiper styles
import "swiper/css/navigation"; // Navigation module styles
import "swiper/css/pagination"; // Pagination module styles
import "./MetaList.scss";

// Import Swiper modules for additional functionality
// import { Nav } from "swiper";


export const MetaList = () => {
  return (
    <div id="our-works" className="meta__list">
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={20}
        // slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        breakpoints={{
          309: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1290: { slidesPerView: 4 },
        }}
      >
        {cardData.map((data) => (
          <SwiperSlide key={data.id}>
            <MetaCard data={data} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
