import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы вверх
  }, [pathname]); // Срабатывает каждый раз, когда меняется маршрут

  return null; // Этот компонент ничего не рендерит
};

export default ScrollToTop;
