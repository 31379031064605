import React from "react";
import "./App.css";
// import { Header } from "./Components/Header/Header";
import { Outlet } from "react-router-dom";
import { Footer } from "./Components/Footer/Footer";

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      {/* <main> */}
      <Outlet />
      {/* </main> */}
      {/* <Footer /> */}
    </div>
  );
}

export default App;
