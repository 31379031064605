import "./lumina.scss";
import "../../styles/page.scss";
import { PageSection } from "../PageSection/PageSection";
import { ColoursSection } from "../ColoursSection/ColoursSection";
import { NavLink } from "react-router-dom";
import { SecondPartOfFooter } from "../SecondPartOfFooter/SecondPartOfFooter";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { HashLink } from "react-router-hash-link";
import { PageListLinks } from "../PageListLinks/PageListLinks";
import { PageFooterButton } from "../PageFooterButton/PageFooterButton";

export const PPLuminaPage = () => {
  const colors = [
    {
      color: "#F5FFD0",
      codeName: "LightGreen",
      name: "Light Green",
      subtitleNames: ["#F5FFD0", "R245, G255, B221"],
      order: "first",
      textColor: "black",
    },
    {
      color: "#2B714B",
      codeName: "Green",
      name: "Green",
      subtitleNames: ["#2B714B", "R43, G113, B75"],
      order: "second",
      textColor: "white",
    },
    {
      color: "#4A4A4A",
      codeName: "Grey",
      name: "Grey",
      subtitleNames: ["#4A4A4A", "R74, G74, B74"],
      order: "third",
      textColor: "white",
    },
    {
      color: "#D9D9D9",
      codeName: "LightGrey",
      name: "Light Grey",
      subtitleNames: ["#D9D9D9", "R217, G217, B217"],
      order: "fourth",
      textColor: "black",
    },
    {
      color: "#FFFFFF",
      codeName: "White",
      name: "White",
      subtitleNames: ["#FFFFFF", "R255, G255, B255"],
      order: "fifth",
      textColor: "black",
    },
    {
      color: "#F0F0F0",
      codeName: "CadetGrey",
      name: "Cadet Grey",
      subtitleNames: ["#F0F0F0", "R240, G240, B240"],
      order: "sixth",
      textColor: "black",
    },
  ];

  return (
    <>
      <header className="page__header__main-container">
        <div className="page__header-container">
          <MobileMenu />
          <div className="page__header">
            <NavLink to="/" className="page-logo-container">
              <img
                src="images/S-letter.svg"
                className="page-logo page-logo-S"
                alt="logo"
              />
              <img
                src="images/O-letter.svg"
                className="page-logo page-logo-O"
                alt="logo"
              />
              <img
                src="images/B-letter.svg"
                className="page-logo page-logo-B"
                alt="logo"
              />
              <img
                src="images/E-letter.svg"
                className="page-logo page-logo-E"
                alt="logo"
              />
              <img
                src="images/X-letter.svg"
                className="page-logo page-logo-X"
                alt="logo"
              />
            </NavLink>
            <PageListLinks />
          </div>
          <div className="lumina__main-container">
            <div className="lumina__title-container">
              <h1 className="lumina__title">Lumina</h1>
              <h2 className="shell__subtitle">
                investment fund. personal profile
              </h2>
            </div>
            <img
              src="images/notebook-img-lumina-header-2.png"
              className="lumina-img"
              alt="notebook-img"
            />
            <img
              src="images/shadow-img-lumina.png"
              className="lumina-img-shadow"
              alt="shadow-img"
            />
          </div>
        </div>
      </header>
      <PageSection
        industry={
          <>
            Web design
            <br />
            Web Development
            <br />
            Social Media
          </>
        }
        boldTitle={"Lumina."}
        title={"brand and Website Design"}
        paragraph={
          <>
            Landing Page Development Project for an Investment Fund <br></br>
            <br></br>
            Our team developed a personalized investment dashboard for fund
            clients—a space where investors can easily and securely manage their
            assets, track returns, and analyze their financial transactions.
            <br></br>
            <br></br>
            What We Did:<br></br>
            <br></br>
            Thoughtful UX/UI Design – we created a user-friendly and intuitive
            interface, allowing users to quickly find necessary information. The
            design focused on simplicity and accessibility, with full adaptation
            for all devices.<br></br>
            <br></br>
            Interactive Control Panel – investors can view current investments,
            returns, transaction details, and transaction history in one place,
            with options for in-depth analysis.<br></br>
            <br></br>
            Banking and Brokerage-Like Features – we integrated portfolio
            analysis, profit tracking, dividend calculation, and performance
            metrics, enabling investors to make well-informed decisions.
            <br></br>
            <br></br>
            Data Security and Privacy – special attention was given to data
            protection, ensuring users feel safe and secure when managing their
            finances.<br></br>
            <br></br>
            Result:<br></br>
            The investor dashboard became a powerful tool for the fund’s
            clients, providing all necessary data and analytics in a convenient
            format. This project gave fund clients real-time access to their
            asset information and the ability to manage their investments
            effectively.
          </>
        }
        year={"2024"}
        projectDescription={
          <>
            Brand identity<br></br>Web design<br></br>Social Media& Advertising
          </>
        }
      />
      <ColoursSection colours={colors} />
      <section className="page__section">
        <img
          src="images/qwerty-img.png"
          className="lumina-img-qwerty"
          alt="qwerty"
        />
        <img
          src="images/qwerty-img.png"
          className="lumina-img-qwerty"
          alt="qwerty"
        />
        <img
          src="images/qwerty-img-2.png"
          className="lumina-img-qwerty"
          alt="qwerty"
        />
      </section>
      <img
        src="images/lumina-phones-img-2.png"
        className="lumina-img-phones page-img"
        alt="phones"
      />
      <footer className="page__footer">
        <div className="lumina-img-container">
          <img
            src="images/notebook-img-lumina-footer-group.png"
            className="lumina-img-notebook-group page-img"
            alt="notebook"
          />
        </div>

        <PageFooterButton link="/greig-and-yard" />
      </footer>
      <SecondPartOfFooter />
    </>
  );
};
