import { HashLink } from "react-router-hash-link";


export const PageListLinks = () => {
  return (
    <ul className="page__list">
      <li className="page__item">
        <HashLink to="/#about" smooth={true} className="page__link">
          About us
        </HashLink>
      </li>
      <li className="page__item">
        <HashLink to="/#partners" smooth={true} className="page__link">
          Our partners
        </HashLink>
      </li>
      <li className="page__item">
        <HashLink to="/#services" smooth={true} className="page__link">
          Services
        </HashLink>
      </li>
      <li className="page__item">
        <HashLink
          to="/#our-works"
          smooth={true}
          className="page__link"
        >
          Our works
        </HashLink>
      </li>
      <li className="page__item">
        <HashLink to="/#contacts" smooth={true} className="page__link">
          Contacts
        </HashLink>
      </li>
    </ul>
  );
};
