import "./greit.scss";
import { NavLink } from "react-router-dom";
import { ColoursSection } from "../ColoursSection/ColoursSection";
import { PageSection } from "../PageSection/PageSection";
import { SecondPartOfFooter } from "../SecondPartOfFooter/SecondPartOfFooter";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { HashLink } from "react-router-hash-link";
import { PageListLinks } from "../PageListLinks/PageListLinks";
import { PageFooterButton } from "../PageFooterButton/PageFooterButton";

export const GreitPage = () => {
  const colors = [
    {
      color: "#B6DAF9",
      codeName: "LightBlue",
      name: "Light Blue",
      subtitleNames: ["#B6DAF9", "R182, G218, B249"],
      order: "first",
      textColor: "black",
    },
    {
      color: "#26A2D8",
      codeName: "Blue",
      name: "Blue",
      subtitleNames: ["#26A2D8", "R38, G162, B216"],
      order: "second",
      textColor: "white",
    },
    {
      color: "#6E6E73",
      codeName: "Grey",
      name: "Grey",
      subtitleNames: ["#6E6E73", "R110, G110, B115"],
      order: "third",
      textColor: "white",
    },
    {
      color: "#151719",
      codeName: "LightBlack",
      name: "Light Black",
      subtitleNames: ["#151719", "R21, G23, B25"],
      order: "fourth",
      textColor: "white",
    },
    {
      color: "#FFFFFF",
      codeName: "White",
      name: "White",
      subtitleNames: ["#FFFFFF", "R255, G255, B255"],
      order: "fifth",
      textColor: "black",
    },
    {
      color: "#8364E2",
      codeName: "Purple",
      name: "Purple",
      subtitleNames: ["#8364E2", "R131, G100, B226"],
      order: "sixth",
      textColor: "black",
    },
  ];

  return (
    <>
      <header className="page__header__main-container">
        <div className="page__header-container">
          <MobileMenu />
          <div className="page__header">
            <NavLink to="/" className="page-logo-container">
              <img
                src="images/S-letter.svg"
                className="page-logo page-logo-S"
                alt="logo"
              />
              <img
                src="images/O-letter.svg"
                className="page-logo page-logo-O"
                alt="logo"
              />
              <img
                src="images/B-letter.svg"
                className="page-logo page-logo-B"
                alt="logo"
              />
              <img
                src="images/E-letter.svg"
                className="page-logo page-logo-E"
                alt="logo"
              />
              <img
                src="images/X-letter.svg"
                className="page-logo page-logo-X"
                alt="logo"
              />
            </NavLink>
            <PageListLinks />
          </div>
          <div className="lumina__main-container">
            <div className="lumina__title-container">
              <h1 className="greit__title">Greit Estate</h1>
              <h2 className="shell__subtitle">Investment fund</h2>
            </div>
            <img
              src="images/notebook-img-greit-header.png"
              className="lumina-img"
              alt="notebook-img"
            />
            <img
              src="images/shadow-img-greit.png"
              className="lumina-img-shadow"
              alt="shadow-img"
            />
          </div>
        </div>
      </header>
      <PageSection
        boldTitle={"Greit estate."}
        title={" brand and Website Design"}
        paragraph={
          <>
            Design Project for the GREIT Estate Platform<br></br>
            <br></br>
            As part of the GREIT Estate platform development, our team designed
            a modern, stylish interface that reflects the innovative nature of
            the project and is user-focused. We aimed to make the platform
            intuitive and appealing to investors, implementing the following
            design approaches:<br></br>
            <br></br>
            Minimalist design with a focus on usability — we created a clean and
            streamlined interface that allows users to navigate easily and focus
            on what matters: investment opportunities.<br></br>
            <br></br>
            Color palette selection associated with trust and stability — colors
            were carefully chosen to create a sense of reliability and
            professionalism, which is essential for an investment product.
            <br></br>
            <br></br>
            Integration of blockchain and NFT elements into the visual concept —
            we incorporated elements that highlight the use of cutting-edge
            technology, allowing users to recognize the innovative approach to
            investment.<br></br>
            <br></br>
            Responsive design for all devices — the platform was developed to
            look and function seamlessly on both desktop and mobile devices,
            ensuring convenient access at any time.<br></br>
            <br></br>
            As a result, the GREIT Estate platform features a well-thought-out
            and functional design that not only looks attractive but also
            provides a comfortable experience for every user.
          </>
        }
        year={"2021"}
        industry={
          <>
            UX Research<br></br>UI/UX Design<br></br>Frontend and Backend
          </>
        }
        projectDescription={
          <>
            Web design<br></br>Web Development
          </>
        }
      />
      <ColoursSection colours={colors} />
      <img
        src="images/qwerty-img.png"
        className="greit-img-qwerty page-img"
        alt="qwerty"
      />
      <footer className="page__footer">
        <div className="greit-img-notebooks-container">
          <img
            src="images/notebook-img-greit-group.png"
            className="greit-img-notebooks page-img"
            alt="notebooks"
          />
        </div>

        <PageFooterButton link="/safe-wallet" />
      </footer>
      <SecondPartOfFooter />
    </>
  );
};
