import { useState, useEffect } from "react";
import "./serviceCard.scss";
import { NavLink } from "react-router-dom";
import { ServiceCardType } from "../../types/card";

type Props = {
  card: ServiceCardType;
};

export const ServiceCard: React.FC<Props> = ({ card }) => {
  const { id, title, description, tags, link } = card;
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMouseEnter = () => {
    if (!isMobile) setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (!isMobile) setIsHovered(false);
  };

  return (
    <div
      key={id}
      className={`card ${isHovered ? "card-expanded" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isMobile || !isHovered ? (
        <NavLink to={`${link}`} className="card-title">
          <h2
            style={{
              fontFamily: `${card.fontFamily}`,
              fontWeight: `${card.fontWeight}`,
              // fontSize: "50px",
              // lineHeight: "40px",
            }}
          >
            {title}
          </h2>
        </NavLink>
      ) : (
        <div className="card-content">
          <h2
            style={{
              fontFamily: `${card.fontFamily}`,
              fontWeight: `${card.fontWeight}`,
              fontSize: "50px",
              lineHeight: "66px",
            }}
            className="card-title"
          >
            {title}
          </h2>
          <p style={{ fontSize: "24px" }} className="description">
            {description}
          </p>
          <div className="tags">
            {tags.map((tag, index) => (
              <h3
                key={index}
                style={{ textTransform: "uppercase" }}
                className="page-design__item"
              >
                {tag}
              </h3>
            ))}
          </div>
          <NavLink
            to={`${link}`}
            style={{ position: "absolute", right: 0, bottom: "40px" }}
            className="page__button page__button-secondary"
          >
            View details
          </NavLink>
        </div>
      )}
    </div>
  );
};
