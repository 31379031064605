import { ColoursSection } from "../ColoursSection/ColoursSection";
import { PageSection } from "../PageSection/PageSection";
import "./boostTg.scss";
import "../../styles/page.scss";
import { PageFooterButton } from "../PageFooterButton/PageFooterButton";
import { NavLink } from "react-router-dom";
import { SecondPartOfFooter } from "../SecondPartOfFooter/SecondPartOfFooter";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-scroll";
import { PageListLinks } from "../PageListLinks/PageListLinks";

export const BoostchainTgPage = () => {
  const colors = [
    {
      color: "#4A4A4A",
      codeName: "Grey",
      name: "Grey",
      subtitleNames: ["#4A4A4A", "R74, G74, B74"],
      order: "first",
      textColor: "white",
    },
    {
      color: "#FCA207",
      codeName: "OrangePeel",
      name: "Orange Peel",
      subtitleNames: ["#FCA207", "R252, G162, B7"],
      order: "second",
      textColor: "black",
    },
    {
      color: "#000000",
      codeName: "Black",
      name: "Black",
      subtitleNames: ["#000000", "R0, G0, B0"],
      order: "third",
      textColor: "white",
    },
    {
      color: "#FFFFFF",
      codeName: "White",
      name: "White",
      subtitleNames: ["#FFFFFF", "R255, G255, B255"],
      order: "fourth",
      textColor: "black",
    },
  ];

  return (
    <>
      <header className="page__header__main-container">
        <div className="page__header-container">
          <MobileMenu />
          <div className="page__header">
            <NavLink to="/" className="page-logo-container">
              <img
                src="images/S-letter.svg"
                className="page-logo page-logo-S"
                alt="logo"
              />
              <img
                src="images/O-letter.svg"
                className="page-logo page-logo-O"
                alt="logo"
              />
              <img
                src="images/B-letter.svg"
                className="page-logo page-logo-B"
                alt="logo"
              />
              <img
                src="images/E-letter.svg"
                className="page-logo page-logo-E"
                alt="logo"
              />
              <img
                src="images/X-letter.svg"
                className="page-logo page-logo-X"
                alt="logo"
              />
            </NavLink>
            <PageListLinks />
          </div>
          <div className="safe__main-container">
            <div className="lumina__title-container">
              <h1
                className="greit__title centuro__title"
                style={{
                  fontSize: window.innerWidth <= 768 ? "90px" : "180px",
                }}
              >
                Boostchain
              </h1>
              <h2 className="shell__subtitle" style={{ fontSize: "40px" }}>
                Crypto accelerator. Telegram bot
              </h2>
            </div>
            <img
              src="images/boost-phone-header.png"
              className="centuro-img-notebook boostTg-img-phone"
              alt="notebook-img"
            />
            <img
              src="images/boost-shadow-header.png"
              className="centuro-img-shadow"
              alt="shadow-img"
            />
          </div>
        </div>
      </header>
      <PageSection
        boldTitle={"Boostchain. telegram bot."}
        title={<>BRAND </>}
        secondTitle={"and WEBSITE DESIGN"}
        paragraph={
          <>
            BoostChain Telegram Bot Development Project<br></br>
            <br></br>
            Our team developed an innovative Telegram bot, BoostChain, designed
            to reward users for inviting friends through a referral link. The
            more friends a user invites, the more BoostCoins (our platform's
            crypto tokens) they earn, allowing them to accumulate rewards and
            participate in exclusive giveaways.<br></br>
            <br></br>
            What We Did:<br></br>
            <br></br>
            Referral-based rewards system — we built a referral system that
            incentivizes users to invite friends, offering increasing rewards in
            BoostCoins as their referral network grows.<br></br>
            <br></br>
            User-friendly interface — designed a clear and intuitive layout for
            easy navigation, ensuring users can quickly understand how to invite
            friends, track rewards, and redeem bonuses.<br></br>
            <br></br>
            Marketplace section — integrated a “Marketplace” section where users
            can view current projects and games, providing additional ways for
            users to engage and earn rewards.<br></br>
            <br></br>
            Bonus levels and challenges — implemented multiple reward levels,
            including special bonuses for reaching certain referral milestones,
            motivating users to stay active on the platform.<br></br>
            <br></br>
            Real-time tracking and notifications — included real-time updates
            and notifications to keep users informed of their progress, new
            reward opportunities, and platform updates.<br></br>
            <br></br>
            Result: BoostChain has become a powerful tool for increasing user
            engagement and brand loyalty. The bot not only provides a fun and
            interactive way to earn rewards but also serves as a hub for
            discovering trending projects and games, making it a valuable
            addition to the social crypto ecosystem.
          </>
        }
        year={"2024"}
        industry={
          <>
            Product Design<br></br>User Flow Mapping<br></br>API Integration
            <br></br>Real-Time Notifications<br></br>Rewards and Incentives
            System
          </>
        }
        projectDescription={
          <>
            Developing a Telegram bot for crypto rewards through referral
            systems<br></br>
            Increasing user engagement via gamified reward structures<br></br>
            Providing a marketplace for exploring new crypto trends and projects
          </>
        }
      />
      <section className="page-colours__title-img-container">
        <img
          src="images/colours-text.png"
          className="page-colours__title-img"
          alt="text-img"
        />
      </section>
      <section className="page-colours">
        <div
          className="page-colours__main-container"
          style={{ gridTemplateRows: "repeat(4, 190px)" }}
        >
          {colors.map((color) => (
            <div
              className={`page-colours-container page-colours-container-other-variant-${color.order}`}
              style={{ backgroundColor: color.color }}
            >
              <h2
                className="page-colours__title"
                style={{ color: color.textColor }}
              >
                {color.name}
              </h2>
              <div className="page-colours__subtitle-container">
                <h3
                  className="page-colours__subtitle"
                  style={{ color: color.textColor }}
                >
                  {color.color}
                </h3>
                {color.subtitleNames.slice(1).map((subtitleName) => (
                  <h3
                    className="page-colours__subtitle"
                    style={{ color: color.textColor }}
                  >
                    {subtitleName}
                  </h3>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
      <img
        src="images/qwerty-img.png"
        className="page-img boostTg-img-qwerty"
        alt="qwerty"
      />
      <footer className="page__footer">
        <img
          src="images/boost-phones-group-footer.png"
          className="page-img boostTg-img-phones"
          alt="phones"
        />
        <PageFooterButton link="/crypto-store" />
      </footer>
      <SecondPartOfFooter />
    </>
  );
};
